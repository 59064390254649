import React,  { useState, useEffect } from "react";
import { Admin, Resource } from "react-admin";
import { createMuiTheme } from '@material-ui/core/styles';

import BugIcon from '@material-ui/icons/BugReport'
import UsersIcon from '@material-ui/icons/SupervisorAccount'
import Users from '@material-ui/icons/Sync'
import AnswersIcon from '@material-ui/icons/SpeakerNotesOutlined'
import PollIcon from '@material-ui/icons/PollOutlined'
// import BugIcon from '@material-ui/icons/BugReport'

import {
  RestProvider,
  AuthProvider,
  base64Uploader
} from "./firestoneProvider";

import { SysUsersCreate, SysUsersList, SysUsersEdit } from "./models/sysUsers";
import { TenantCreate, TenantList, TenantEdit } from "./models/tenant";
import { SurveysCreate, SurveysList, SurveysEdit, AdminSurveyList } from "./models/surveys";
import { AnswersList } from "./models/answers";

const firebaseConfig = require('./credencials.json');

const trackedResources = [
  {
    name: "SURVEYS_USERS",
    isPublic: false
  },
  {
    name: "SURVEYS_TENANTS",
    isPublic: false
  },
  {
    name: "SURVEYS_POLLS",
    isPublic: false
  },
];

const authConfig = {
  userProfilePath: "/SURVEYS_USERS/",
  userAdminProp: "isAdmin"
};

const dataProvider = base64Uploader(
  RestProvider(firebaseConfig, { trackedResources })
);

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#C0102E',
      main: '#263238',
      dark: '#263238',
      contrastText: '#FFF',
    },
    secondary: {
      light: '#e3f2fd',
      main: '#3f51b5',
      dark: '#333',
      contrastText: '#fff',
    }
  },
});

const App: React.FC = (props) => { 

  useEffect(() => {}, [])

  return (
    <Admin 
      theme={theme}
      title="Backoffice"
      dataProvider={dataProvider} 
      authProvider={AuthProvider(authConfig)}>

      {
      <Resource
        name="SURVEYS_USERS"
        options={{label: 'Users'}}
        icon={UsersIcon}
        create={SysUsersCreate}  
        list={localStorage.getItem('role') == 'admin' && SysUsersList}        
        edit={SysUsersEdit}
      />}

      <Resource
        name="SURVEYS_POLLS"
        options={{label: 'Polls'}}
        icon={PollIcon}
        edit={localStorage.getItem('role') == 'admin' && SurveysEdit}
        create={localStorage.getItem('role') == 'admin' && SurveysCreate}  
        list={SurveysList}        
      />


      <Resource
        name="SURVEYS_ANSWERS"
        options={{label: 'Answers'}}
        icon={AnswersIcon}
        list={AnswersList}        
      />

      {/* {
        (profile && profile.tenant == '') &&
      } */}
{/*     
      <Resource
        name="tenants"
        options={{label: 'Instancias'}}
        icon={BugIcon}
        create={TenantCreate}  
        list={TenantList}        
        edit={TenantEdit}
      /> */}
       
    </Admin>
  );
};

export default App;
