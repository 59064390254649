/* globals localStorage */
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK } from 'react-admin';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const baseConfig = {
  userProfilePath: '/SURVEYS_USERS/',
  userAdminProp: 'isAdmin',
  localStorageTokenName: 'RAFirebaseClientToken222',
  handleAuthStateChange: async (auth, config) => {
    if (auth && (auth.user.uid || auth.uid)) {
      const snapshot = await firebase
        .firestore()
        .collection(config.userProfilePath)
        .where('uid', '==', auth.user.uid || auth.uid)
        .limit(1)
        .get();
      let result = []
      snapshot.forEach(doc => result.push({ ...doc.data(), id: doc.id }))

      const profile = result[0]
      if (result.length === 0) throw new Error('No encontramos al usuario')

      if (profile && profile[config.userAdminProp]) {
        let surveys = await firebase.firestore()
        .collection('SURVEYS_POLLS')
        .where('users', 'array-contains', profile.id)
        .get();
        let ids = []
        surveys.forEach(e => ids.push(e.id))
        const firebaseToken = auth.refreshToken;
        let user = { auth, profile, firebaseToken, ids };
        localStorage.setItem(config.localStorageTokenName, firebaseToken);
        localStorage.setItem('tenant', profile.tenant);
        localStorage.setItem('id_surveys', JSON.stringify({ids}));
        localStorage.setItem('role', profile.role );
        localStorage.setItem('id', profile.id);
        return user;
      } 
      else {
        firebase.auth().signOut();
        localStorage.removeItem(config.localStorageTokenName);
        throw new Error('sign_in_error');
      }
    } 
    else {
      localStorage.removeItem(config.localStorageTokenName);
      throw new Error('sign_in_error');
    }
  }
};

export default (config = {}) => {
  config = { ...baseConfig, ...config };

  const firebaseLoaded = () =>
    new Promise(resolve => {
      firebase.auth().onAuthStateChanged(resolve);
    });

  return async (type, params) => {
    if (type === AUTH_LOGOUT) {
      config.handleAuthStateChange(null, config).catch(() => {});
      return firebase.auth().signOut();
    }

    if (firebase.auth().currentUser) {
      await firebase.auth().currentUser.reload();
    }

    if (type === AUTH_CHECK) {
      await firebaseLoaded();
      if (!firebase.auth().currentUser) {
        throw new Error('sign_in_error');
      }

      return true;
    }

    if (type === AUTH_LOGIN) {
      const { username, password } = params;
      let auth = firebase.auth().currentUser;
      if (!auth) {
        auth = await firebase.auth().signInWithEmailAndPassword(username, password);
      }

      return config.handleAuthStateChange(auth, config);
    }

    return false;
  };
};
