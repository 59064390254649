import firebase from 'firebase/app';
import moment from 'moment';
import 'firebase/firestore';
import 'firebase/storage';
import sortBy from 'sort-by';
import {
  CREATE
} from 'react-admin';

const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const addUploadFeature = requestHandler => (type, resource, params) => {
  if (type === 'UPDATE') {
    if (params.data.image && params.data.image.length) {
      const formerPictures = params.data.image.filter(p => !(p.rawFile instanceof File));
      const newPictures = params.data.image.filter(p => p.rawFile instanceof File);

      return Promise.all(newPictures.map(convertFileToBase64))
        .then(base64Pictures =>
          base64Pictures.map(picture64 => ({
            src: picture64,
            title: `${params.data.title}`
          }))
        )
        .then(transformedNewPictures =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              image: [...transformedNewPictures, ...formerPictures]
            }
          })
        );
    }
  }
  // for other request types and reources, fall back to the defautl request handler
  return requestHandler(type, resource, params);
};



const getImageSize = file => {
  return new Promise(resolve => {
    const img = document.createElement('img');
    img.onload = function () {
      resolve({
        width: this.width,
        height: this.height
      });
    };
    img.src = file.src;
  });
};

const upload = async (fieldName, submitedData, id, resourceName, resourcePath) => {
  const file = submitedData[fieldName] && submitedData[fieldName][0];
  const rawFile = file.rawFile;

  const result = {};
  if (file && rawFile && rawFile.name) {
    const ref = firebase
      .storage()
      .ref()
      .child(`${resourcePath}/${id}/${fieldName}`);
    const snapshot = await ref.put(rawFile);
    result[fieldName] = [{}];
    result[fieldName][0].uploadedAt = new Date();
    result[fieldName][0].src = snapshot.downloadURL.split('?').shift() + '?alt=media';
    result[fieldName][0].type = rawFile.type;
    if (rawFile.type.indexOf('image/') === 0) {
      try {
        const imageSize = await getImageSize(file);
        result[fieldName][0].width = imageSize.width;
        result[fieldName][0].height = imageSize.height;
      } catch (e) {
        console.error(`Failed to get image dimensions`);
      }
    }
    return result;
  }
  return false;
};

const save = async (
  id,
  data,
  previous,
  resourceName,
  resourcePath,
  firebaseSaveFilter,
  uploadResults,
  isNew,
  timestampFieldNames
) => {
  if (uploadResults) {
    uploadResults.map(uploadResult => (uploadResult ? Object.assign(data, uploadResult) : false));
  }

  if (isNew) {
    Object.assign(data, {
      [timestampFieldNames.createdAt]: new Date()
    });
  }

  data = Object.assign(previous, {
    [timestampFieldNames.updatedAt]: new Date()
  }, data);

  // data id is only used for react admin flow. we remove it before saving a document and will use firestore id
  if (data.id) {
    delete data.id
  }


  await firebase
    .firestore()
    .doc(id ? `${resourcePath}/${id}` : resourcePath)
    .set(firebaseSaveFilter(data));
  return {
    data: {
      ...data,
      id
    }
  };
};

const del = async (id, resourceName, resourcePath, uploadFields) => {
  if (uploadFields.length) {
    uploadFields.map(fieldName =>
      firebase
      .storage()
      .ref()
      .child(`${resourcePath}/${id}/${fieldName}`)
      .delete()
    );
  }

  await firebase
    .firestore()
    .doc(`${resourcePath}/${id}`)
    .delete();
  return {
    data: id
  };
};

const delMany = async (ids, resourceName, previousData) => {
  await ids.map(id =>
    firebase
    .firestore()
    .doc(`${resourceName}/${id}`)
    .delete()
  );
  return {
    data: ids
  };
};

const getItemID = (params, type, resourceName, resourcePath, resourceData) => {
  let itemId = params.data.id || params.id || params.data.key || params.key;
  if (!itemId) {
    itemId = firebase
      .firestore()
      .collection(resourcePath)
      .doc().id;
  }

  if (!itemId) {
    throw new Error('ID is required');
  }

  if (resourceData && resourceData[itemId] && type === CREATE) {
    throw new Error('ID already in use');
  }

  return itemId;
};

const getOne = async (params, resourceName, resourceData) => {
  if (params.id) {
    let result = await firebase
      .firestore()
      .collection(resourceName)
      .doc(params.id)
      .get();

    if (result.exists) {
      const data = result.data();

      if (data && data.id == null) {
        data['id'] = result.id;
      }
      return {
        data: data
      };
    } else {
      throw new Error('Id not found');
    }
  } else {
    throw new Error('Id not found');
  }
};

let lastIndex = null

let totals = {}

const getKey = (resource) => {
  switch (resource) {
    case "SURVEYS_TENANTS":
      return "name"
    case "SURVEYS_USERS":
      return "uid"
    case "SURVEYS_POLLS":
      return "name"
    case "mails":
      return "to"
    case "SURVEYS_ANSWERS":
      return 'timestamp'

    default:
      break;
  }
}

let lasts = []
let pages = null;

const getPages = async (query, perPage) => {
  let q = await query.get();
  let p = q.size() / perPage;
  for (let index = 0; index < p; index++) {
    // const element = array[index];

  }
}

// if(!pages) {
//   pages = getPages(initQuery)
// }

const getList = async (params, resourceName, resourceData) => {
  let initQuery = firebase.firestore().collection(resourceName).orderBy(getKey(resourceName), (params.sort && params.sort.field === 'datetime' && params.sort.order === 'ASC') ? 'asc' :'desc')
  if (params.pagination) {
    let values = [];
    let total =  0
    let snapshots = null

    if (params.filter && Object.keys(params.filter).length > 0 ) {
      let filterQuery = initQuery

      Object.keys(params.filter).forEach(item => {
        if(item === 'users' && resourceName === 'SURVEYS_POLLS'){
          filterQuery = filterQuery.where(item, 'array-contains', params.filter[item])
        }
        else if(item === 'survey_id' && resourceName === 'SURVEYS_ANSWERS' && !params.filter['initDate']){
          filterQuery = filterQuery.where(item, 'in', params.filter[item].ids)
        }
        else if((params.filter['initDate'] || params.filter['endDate']) && resourceName === 'SURVEYS_ANSWERS'){
          let init = moment(params.filter['initDate']).subtract(3, 'hours').unix()
          let end = moment(params.filter['endDate']).subtract(3, 'hours').unix()
          if (moment(init).diff(moment(end), 'month') > 1) {
            throw new Error('El tiempo debe ser menor a 1 mes');
          }
          if(item === 'endDate' && Number(end)) filterQuery = filterQuery.where('timestamp', '<=', end)
          if(item === 'initDate' && Number(init)) filterQuery = filterQuery.where('timestamp', '>=', init)
        }
        else {
          filterQuery = filterQuery.where(item, '==', params.filter[item])
        }
      })

      if(resourceName === 'SURVEYS_ANSWERS'){
        filterQuery = filterQuery.where('timestamp', '>=', Number(moment().subtract(30,'days').format('X')))
      }

      if(totals[resourceName]) {
        total = totals[resourceName]
      }
      else {
        total = await (await filterQuery.get()).size
        // totals[resourceName] = total
      }

      if (total < 1) return {
        data: [],
        ids: [],
        total: 0
      };

      if (params.pagination.page !== 1) {
        let n = params.pagination.perPage * params.pagination.page
        let last = await filterQuery.limit(n).get()
        snapshots = await filterQuery.startAfter(last.docs[last.size - 1].data().timestamp).limit(params.pagination.perPage|| 10).get();
      }
      else {
        snapshots = await filterQuery.limit(params.pagination.perPage|| 10).get()
      }
    }

    else {
      total = await (await initQuery.get()).size

      if (params.pagination.page !== 1) {
        let qu = await initQuery.limit(params.pagination.perPage * params.pagination.page).get()
        let last = qu.docs[qu.size-1].data()

        snapshots = await initQuery.startAfter(last[getKey(resourceName)]).limit(params.pagination.perPage|| 10).get();
      }
      else {
        snapshots = await initQuery.limit(params.pagination.perPage|| 10).get()
      }
    }

    for (const snapshot of snapshots.docs) {
      const data = snapshot.data();
      if (data && data.id == null) {
        data['id'] = snapshot.id;
      }
      values.push(data);
    }

    const keys = values.map(i => i.id);
    // lastIndex = values[values.length - 1][getKey(resourceName)]
    return {
      data: values,
      ids: keys,
      total
    };
  }
  else {
    throw new Error('Error processing request');
  }
};

const getMany = async (params, resourceName, resourceData) => {
  let data = [];
  /* eslint-disable no-await-in-loop */
  for (const id of params.ids) {
    let {
      data: item
    } = await getOne({
      id
    }, resourceName, resourceData);
    data.push(item);
  }
  return {
    data
  };
};

const getManyReference = async (params, resourceName, resourceData) => {
  if (params.target) {
    if (!params.filter) params.filter = {};
    params.filter[params.target] = params.id;
    let {
      data,
      total
    } = await getList(params, resourceName, resourceData);
    return {
      data,
      total
    };
  } else {
    throw new Error('Error processing request');
  }
};

export default {
  upload,
  save,
  del,
  delMany,
  getItemID,
  getOne,
  getList,
  getMany,
  getManyReference,
  addUploadFeature,
  convertFileToBase64
};