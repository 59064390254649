import React, {Fragment} from "react";
import {
  Create,
  Edit,
  List,
  SimpleForm,
  TextInput,
  LongTextInput,
  required,
  SelectInput,
  ReferenceInput,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton, 
  ShowButton,
  BooleanField,
  Filter,
  ArrayInput,
  BulkDeleteButton,
  SimpleFormIterator,
  ReferenceArrayInput,
  SelectArrayInput,
  FormDataConsumer,
  BooleanInput,
  
} from "react-admin";

const TokenGenerator = require('uuid-token-generator');
 
const tokgen = new TokenGenerator(); // Default is a 128-bit token encoded in base58

const Filters = (props: any) => (
  <Filter {...props}>
      <TextInput style={{display: 'none'}} label="users" source="users" alwaysOn/>
  </Filter>
);
const AdminFilters = (props: any) => (
  <Filter {...props}>
      <TextInput style={{display: 'none'}} label="users" source="tenant" alwaysOn/>
  </Filter>
);

export const SurveysList = (props: any) => localStorage.getItem('role') == 'admin' ? <AdminSurveyList {...props} /> :(
  <List {...props} bulkActionButtons={()=> null} filters={<Filters />} title={'Polls'} filterDefaultValues={{ users: localStorage.getItem('id'), tenant: localStorage.getItem('tenant') }} style={{alignItems: 'center', jusifyContent:'center'}}>
    <Datagrid>
        <TextField source="name" /> 
        <TextField source="id_survey" /> 
        <BooleanField label="Active" source="isAdmin" />
    </Datagrid>
  </List>
);

const UserSurveyBulkActions = (props:any) => (
  <Fragment>
      
  </Fragment>
);
const AdminSurveyBulkActions = (props:any) => (
  <Fragment>
      <BulkDeleteButton {...props} />
  </Fragment>
);

export const AdminSurveyList = (props: any) => (
<List {...props}  bulkActionButtons={<AdminSurveyBulkActions />} filters={<AdminFilters />} title={'Polls'} filterDefaultValues={{ tenant: localStorage.getItem('tenant') }} style={{alignItems: 'center', jusifyContent:'center'}}>
    <Datagrid>
        <TextField source="name" /> 
        <TextField source="id_survey" /> 
        <BooleanField label="Active" source="isAdmin" />
      <EditButton />
    </Datagrid>
  </List>
);

export const SurveysEdit = (props: any) => (
  <Edit {...props} title={'Edit Poll'}>
    <SimpleForm>
      <TextInput source="name" fullWidth />
      <TextInput disabled source="id_survey" fullWidth />
        <ReferenceArrayInput fullWidth source="users" label="Usuarios" reference="SURVEYS_USERS">
            <FormDataConsumer>
              {({ formData, ...rest }: any) => {
                if(!formData) return null  
                let choices = rest.choices.filter((item: any) => item &&  formData && item.tenant === formData.tenant)
                choices = choices.map((ite:any) => ({ ...ite, id: ite.id }))
                return <SelectArrayInput fullWidth style={{minWidth: 280}} choices={choices} source="users" optionText="name" />
              }}
            </FormDataConsumer>
        </ReferenceArrayInput>  
        <TextInput disabled fullWidth source="url" />
        <ArrayInput label="Questions" fullWidth source="questions">
          <SimpleFormIterator fullWidth>
              <TextInput source="question" label="Question" fullWidth />
          </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const SurveysCreate = (props: any) => {
  let token  = tokgen.generate();
  let url  = 'https://us-central1-insideone.cloudfunctions.net/IO_SURVEYS_ADD_RESPONSE';
return(
  <Create {...props} title={'New Poll'}>
    <SimpleForm redirect="list">
        <TextInput disabled style={{display: 'none'}} source="tenant" defaultValue={localStorage.getItem('tenant')} /> 
        <TextInput disabled source="token" style={{display: 'none'}} defaultValue={token} />
        <TextInput source="name" />
        <ReferenceArrayInput source="users" label="Usuarios" reference="SURVEYS_USERS">
            <FormDataConsumer>
              {({ formData, ...rest }: any) => {
                if(!formData) return null  
                let choices = rest.choices.filter((item: any) => item &&  formData && item.tenant === formData.tenant)
                choices = choices.map((ite:any) => ({ ...ite, id: ite.id }))
                // return null
                return <SelectArrayInput style={{minWidth: 280}} choices={choices} source='users' optionText="name" />
              }}
            </FormDataConsumer>
        </ReferenceArrayInput>  
        <ArrayInput label="Questions" fullWidth source="questions">
          <SimpleFormIterator fullWidth>
              <TextInput source="question" label="type a question" fullWidth />
          </SimpleFormIterator>
      </ArrayInput>
          <TextInput disabled fullWidth defaultValue={`${url}?token=${token}`} source="url" />
        <BooleanInput style={{display: 'none'}} source="isAdmin" defaultValue={true} />
    </SimpleForm>
  </Create>
)}
