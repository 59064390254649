import React, {Fragment} from "react";
import {
  List,
  TextInput,
  Datagrid,
  TextField,
  Filter,
  DateTimeInput,
  BulkDeleteButton,
} from "react-admin";

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import GetApp from '@material-ui/icons/GetApp'
import Toolbar from '@material-ui/core/Toolbar';
import moment from 'moment';

const PostActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}:any) => (
  <Toolbar>
      {filters && React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
      })}
      <Button color="primary" onClick={()=> window.location.href = `https://us-central1-insideone.cloudfunctions.net/EXPORT_ANSWERS?params=${JSON.stringify(filterValues)}` }>
        <GetApp style={{marginRight: 5}} />
        Export
      </Button>
  </Toolbar>
);

const CustomFilter = (props: any) => (
  <Filter {...props}>
      <TextInput style={{display: 'none'}} label="" source="survey_id" alwaysOn/>
      <DateTimeInput label="Desde" source="initDate" alwaysOn/>
      <DateTimeInput label="Hasta" source="endDate" alwaysOn/>
  </Filter>
);
const AdminFilter = (props: any) => (
  <Filter {...props}>
      <TextInput style={{display: 'none'}} label="" source="tenant" alwaysOn/>
      <DateTimeInput label="Desde" source="initDate" alwaysOn/>
      <DateTimeInput label="Hasta" source="endDate" alwaysOn/>
  </Filter>
);

const AdminSurveyBulkActions = (props:any) => (
  <Fragment>
      <BulkDeleteButton {...props} />
  </Fragment>
);

export const AnswersList = (props: any) => {
  let ids = localStorage.getItem('id_surveys') || '';
  let isAdmin = localStorage.getItem('role') == 'admin';
  return(
  <List perPage={100} actions={<PostActions />}  bulkActionButtons={isAdmin ?<AdminSurveyBulkActions />: <Fragment />} filters={isAdmin ? <AdminFilter/> :<CustomFilter />} filterDefaultValues={isAdmin? {tenant: localStorage.getItem('tenant') } :{ survey_id: JSON.parse(ids), initDate: moment().subtract('1', 'month'), endDate: moment() }} {...props} title={'Answers'} style={{alignItems: 'center', jusifyContent:'center'}}>
    <Datagrid>
      <TextField source="datetime" />
      <TextField source="id_survey" />
      <TextField source="identifier" />
      <TextField source="sessionId" />
      <TextField source="id_question" />
      <TextField source="value_answer" />
      <TextField source="service" />
    </Datagrid>
  </List>
)};