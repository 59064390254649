import firebase from 'firebase/app'
import 'firebase/storage'

const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const uploadFiles = (name, file, resource) =>
  new Promise((resolve, reject) => {
    let url = `https://firebasestorage.googleapis.com/v0/b/collabcasen.appspot.com/o/%2F${name.replace(/ /g,"_")}?alt=media`
    return firebase.storage().ref().child(`${name.replace(/ /g,"_")}`).put(file.rawFile)
    .then(r => {
      console.log('====================================');
      console.log(r);
      console.log('====================================');
      resolve({src:url, title: name.replace(/ /g,"_")})
    })
    .catch(err => reject(err))
  });


export default (requestHandler) => async (type, resource, params) => {
  if (type === 'UPDATE' || type === 'CREATE') {
    let prop = 'file'
    let imagestoUploadDesktop = params.data[prop] && params.data[prop].filter((i) => i.rawFile)
    let imagestoUploadMobile = params.data[prop+'_mobile'] && params.data[prop+'_mobile'].filter((i) => i.rawFile)
    if (params.data[prop] && imagestoUploadDesktop.length) {

      let imagestoUpload = params.data[prop].filter((i) => i.rawFile)
      let imagesAlready = params.data[prop].filter((i) => !i.rawFile)

      let urlsPromises = imagestoUpload.map((item) => uploadFiles(item.title, item, `${resource}/${params.id}`))

      return Promise.all(urlsPromises).then(urls => requestHandler(type, resource, {...params,
        data: { ...params.data,
          [prop]: [ ...imagesAlready, ...urls]
        }
      })) 
    }
    if (params.data[prop+'_mobile'] && imagestoUploadMobile.length) {
      console.log("MOBI:E")
      let imagestoUpload = params.data[prop+'_mobile'].filter((i) => i.rawFile)
      let imagesAlready = params.data[prop+'_mobile'].filter((i) => !i.rawFile)
      let urlsPromises = imagestoUpload.map((item) => uploadFiles(item.title, item, `${resource}/${params.id}`))
      return Promise.all(urlsPromises).then(urls => requestHandler(type, resource, {...params,
        data: { ...params.data,
          [prop+'_mobile']: [ ...imagesAlready, ...urls]
        }
      })) 
    }
  }
  // for other request types and reources, fall back to the defautl request handler
  return requestHandler(type, resource, params);
};


