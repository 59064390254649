import React from "react";
import {
  Create,
  Edit,
  List,
  SimpleForm,
  TextInput,
  SelectInput,
  Datagrid,
  TextField,
  EditButton, 
  BooleanField,
  Filter,
  TopToolbar,
  BooleanInput, 
  SaveButton,
  DeleteButton,
  Toolbar,
  FormDataConsumer
} from "react-admin";

import Button from '@material-ui/core/Button';

const sendResetEmail = () => {};

const PostEditActions = ({ basePath, data, resource }: any) => (
  <TopToolbar>
      <Button color="primary" onClick={sendResetEmail}>Reiniciar Password</Button>
  </TopToolbar>
);

const CustomToolbar = (props:any) => (
  <Toolbar {...props}>
      <SaveButton />
      <Button color="primary" onClick={sendResetEmail}>Reiniciar Password</Button>
      <DeleteButton undoable={false} />
  </Toolbar>
);

const Filters = (props: any) => (
  <Filter {...props}>
      <TextInput disabled style={{display: 'none'}} label="instacia" source="tenant" alwaysOn/>
  </Filter>
);

export const SysUsersList = (props: any) => (
  <List {...props} filters={<Filters />} title={'Users'} filterDefaultValues={{ tenant: localStorage.getItem('tenant') }} style={{alignItems: 'center', jusifyContent:'center'}}>
    <Datagrid>
        <TextField source="name" /> 
        <TextField source="email" /> 
        <BooleanField label="Active" source="isAdmin" />
      <EditButton />
    </Datagrid>
  </List>
);

export const SysUsersEdit = (props: any) => (
  <Edit {...props} title={'Edit User'}>
    <SimpleForm>
        <TextInput source="name" /> 
        <TextInput source="email" /> 
        <SelectInput choices={[{id: 'admin', name: 'Admin'}, {id: 'user', name: 'User'}]} source="role" optionText="name" /> 
        <BooleanInput label="Active" source="isAdmin" />
        <BooleanInput source="changePass" label="Change Password ?" defaultValue={false} />
        <FormDataConsumer>
              {({ formData, ...rest }: any) => {
                return formData.changePass && <TextInput source="newPassword" label="New Password" /> 
                }
              }
        </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

export const SysUsersCreate = (props: any) => (
  <Create {...props} title={'New User'}>
    <SimpleForm redirect="list">
        <TextInput disabled style={{display: 'none'}} source="tenant" defaultValue={localStorage.getItem('tenant')} /> 
        <TextInput source="name" /> 
        <TextInput source="email" /> 
        <TextInput source="password" /> 
        <SelectInput choices={[{id: 'admin', name: 'Admin'}, {id: 'user', name: 'User'}]} source="role" optionText="name" /> 
        <BooleanInput style={{display: 'none'}} source="isAdmin" defaultValue={true} />
    </SimpleForm>
  </Create>
);


